import React from 'react';

import { Page } from 'components/_shared/Page';

import { useStrapiCommonContent } from 'hooks';
import { parseMarkdown } from 'utils';

export default function Page404() {
  const commonContent = useStrapiCommonContent();

  return (
    <Page
      meta={{ title: '404: Not Found', description: '404: Not Found' }}
      withConnectSection={false}
    >
      <div className='header-spacer h-24' />
      <div className=' w-full flex flex-col items-center pb-12 pt-6 px-6 relative md:px-12 lg:pt-12'>
        {parseMarkdown(commonContent.pagenonfoundlabel)}
      </div>
    </Page>
  );
}
